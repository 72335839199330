//js

if (!global._babelPolyfill) {

  require("babel-polyfill");

}

require("./js/slider");

require("./js/helper");

require("./js/navigation");

require("./js/main");

require("./js/copyPostLink");

require("./js/gallery");

require("./js/sygnalist");

require("./js/carrerArrows");



//fonts

// require('./fonts/font/stylesheet.css');



// css

import "normalize.css";

require("./sass/style.scss");

