document.addEventListener("DOMContentLoaded", function () {
  const buttons = document.querySelectorAll(".sygnalist-button");
  const contents = document.querySelectorAll(".sygnalistContentSignle");

  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      const index = button.getAttribute("data-index");

      // Ukryj wszystkie treści
      contents.forEach((content) => {
        content.style.display = "none";
      });

      // Usuń klasę 'active' ze wszystkich przycisków
      buttons.forEach((btn) => {
        btn.classList.remove("active");
      });

      // Pokaż wybraną treść
      const contentToShow = document.querySelector(`#content-${index}`);
      if (contentToShow) {
        contentToShow.style.display = "block";
      }

      // Dodaj klasę 'active' do klikniętego przycisku
      button.classList.add("active");
    });
  });
});
