const shareIcon = document.querySelector(".singlePost__share");
const shareAlert = document.querySelector(".singlePost__shareAlert");

if (shareIcon) {
  shareIcon.addEventListener("click", () => {
    shareAlert.style.opacity = "1";

    const dummy = document.createElement("input"),
      text = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    setTimeout(function() {
      shareAlert.style.opacity = "0";
    }, 2500);
  });
}
